import { t } from "@cassiozen/usestatemachine";

export enum CStates {
  HOME = "HOME",
  OBJECT_EXPLORER = "OBJECT_EXPLORER",
  DOMAIN_ELEMENT_EDITOR = "DOMAIN_ELEMENT_EDITOR",
  PROJECTION_EDITOR = "PROJECTION_EDITOR",
  COMPOSED_DOMAIN_EDITOR = "COMPOSED_DOMAIN_EDITOR",
  OBJECT_FIELD_DOMAIN_EDITOR = "OBJECT_FIELD_DOMAIN_EDITOR",
  FIXED_TIME_DOMAIN_EDITOR = "FIXED_TIME_DOMAIN_EDITOR",
  GROUP_HANDLER = "GROUP_HANDLER",
  GROUP_PREVIEW = "GROUP_PREVIEW",
  FILTER_HANDLER = "FILTER_HANDLER",
}

export enum CEvents {
  EXPLORE_OBJECTS = "EXPLORE_OBJECTS",
  CREATE_PROJECTION = "CREATE_PROJECTION",
  PICK_OBJECT_OR_FIELDS = "PICK_OBJECT_OR_FIELDS",
  CREATE_EDIT_DOMAIN = "CREATE_EDIT_DOMAIN",
  CONFIGURE_OBJECT_FIELD_DOMAIN = "CONFIGURE_OBJECT_FIELD_DOMAIN",
  CONFIGURE_TIME_DOMAIN = "CONFIGURE_TIME_DOMAIN",
  CREATE_SUBGROUPS = "CREATE_SUBGROUPS",
  HANDLE_JOINS_OR_GROUPS = "HANDLE_JOINS_OR_GROUPS",
  VIEW_GROUP_PREVIEW = "VIEW_GROUP_PREVIEW",
  GO_BACK = "GO_BACK",
  HANDLER_COMPLETE = "HANDLER_COMPLETE",
  CREATE_EDIT_FILTERS = "CREATE_EDIT_FILTERS",
  NOOP = "NOOP",
}

export const events: Record<CEvents, { [k: string]: unknown }> = {
  [CEvents.EXPLORE_OBJECTS]: t(),
  [CEvents.CREATE_PROJECTION]: t(),
  [CEvents.PICK_OBJECT_OR_FIELDS]: t(),
  [CEvents.CREATE_EDIT_DOMAIN]: t(),
  [CEvents.CONFIGURE_OBJECT_FIELD_DOMAIN]: t(),
  [CEvents.CONFIGURE_TIME_DOMAIN]: t(),
  [CEvents.CREATE_SUBGROUPS]: t(),
  [CEvents.HANDLE_JOINS_OR_GROUPS]: t(),
  [CEvents.VIEW_GROUP_PREVIEW]: t(),
  [CEvents.GO_BACK]: t(),
  [CEvents.HANDLER_COMPLETE]: t(),
  [CEvents.CREATE_EDIT_FILTERS]: t(),
  [CEvents.NOOP]: t(),
};

export const eventLabels: Record<CEvents, string> = {
  [CEvents.EXPLORE_OBJECTS]: "Explore Objects",
  [CEvents.CREATE_PROJECTION]: "Create Projection",
  [CEvents.PICK_OBJECT_OR_FIELDS]: "Pick Object or Fields",
  [CEvents.CREATE_EDIT_DOMAIN]: "Create or Edit Domain",
  [CEvents.CONFIGURE_OBJECT_FIELD_DOMAIN]: "Configure Object Field Domain",
  [CEvents.CONFIGURE_TIME_DOMAIN]: "Configure Time Domain",
  [CEvents.CREATE_SUBGROUPS]: "Create Subgroups",
  [CEvents.HANDLE_JOINS_OR_GROUPS]: "Handle Joins or Groups",
  [CEvents.VIEW_GROUP_PREVIEW]: "View Group Preview",
  [CEvents.GO_BACK]: "Go Back",
  [CEvents.HANDLER_COMPLETE]: "Handler Complete",
  [CEvents.CREATE_EDIT_FILTERS]: "Handler Complete",
  [CEvents.NOOP]: "No Operation",
};

interface StateTransition {
  on: Partial<Record<CEvents, CStates>>;
}

export const states: Record<CStates, StateTransition> = {
  [CStates.HOME]: {
    on: {
      [CEvents.NOOP]: CStates.HOME,
      [CEvents.EXPLORE_OBJECTS]: CStates.OBJECT_EXPLORER,
      [CEvents.CREATE_PROJECTION]: CStates.PROJECTION_EDITOR,
    },
  },
  [CStates.OBJECT_EXPLORER]: {
    on: {
      [CEvents.NOOP]: CStates.OBJECT_EXPLORER,
      [CEvents.CREATE_PROJECTION]: CStates.PROJECTION_EDITOR,
      [CEvents.GO_BACK]: CStates.HOME,
    },
  },
  [CStates.DOMAIN_ELEMENT_EDITOR]: {
    on: {
      [CEvents.NOOP]: CStates.DOMAIN_ELEMENT_EDITOR,
      [CEvents.CREATE_PROJECTION]: CStates.PROJECTION_EDITOR,
      [CEvents.GO_BACK]: CStates.PROJECTION_EDITOR,
    },
  },
  [CStates.PROJECTION_EDITOR]: {
    on: {
      [CEvents.NOOP]: CStates.PROJECTION_EDITOR,
      [CEvents.CREATE_EDIT_DOMAIN]: CStates.DOMAIN_ELEMENT_EDITOR,
      [CEvents.HANDLE_JOINS_OR_GROUPS]: CStates.GROUP_HANDLER,
      [CEvents.CREATE_EDIT_FILTERS]: CStates.FILTER_HANDLER,
      [CEvents.GO_BACK]: CStates.HOME,
    },
  },
  [CStates.COMPOSED_DOMAIN_EDITOR]: {
    on: {
      [CEvents.NOOP]: CStates.COMPOSED_DOMAIN_EDITOR,
      [CEvents.CONFIGURE_OBJECT_FIELD_DOMAIN]:
        CStates.OBJECT_FIELD_DOMAIN_EDITOR,
      [CEvents.CONFIGURE_TIME_DOMAIN]: CStates.FIXED_TIME_DOMAIN_EDITOR,
      [CEvents.GO_BACK]: CStates.PROJECTION_EDITOR,
    },
  },
  [CStates.OBJECT_FIELD_DOMAIN_EDITOR]: {
    on: {
      [CEvents.NOOP]: CStates.OBJECT_FIELD_DOMAIN_EDITOR,
      [CEvents.PICK_OBJECT_OR_FIELDS]: CStates.OBJECT_EXPLORER,
      [CEvents.GO_BACK]: CStates.COMPOSED_DOMAIN_EDITOR,
    },
  },
  [CStates.FIXED_TIME_DOMAIN_EDITOR]: {
    on: {
      [CEvents.NOOP]: CStates.FIXED_TIME_DOMAIN_EDITOR,
      [CEvents.GO_BACK]: CStates.COMPOSED_DOMAIN_EDITOR,
    },
  },
  // MeasureOneToOne | MeasureAgg | MeasureProjection;
  [CStates.GROUP_HANDLER]: {
    on: {
      [CEvents.NOOP]: CStates.GROUP_HANDLER,
      [CEvents.VIEW_GROUP_PREVIEW]: CStates.GROUP_PREVIEW,
      [CEvents.HANDLER_COMPLETE]: CStates.PROJECTION_EDITOR,
      [CEvents.GO_BACK]: CStates.PROJECTION_EDITOR,
    },
  },
  [CStates.GROUP_PREVIEW]: {
    on: {
      [CEvents.NOOP]: CStates.GROUP_PREVIEW,
      [CEvents.GO_BACK]: CStates.GROUP_HANDLER,
    },
  },
  [CStates.FILTER_HANDLER]: {
    on: {
      [CEvents.NOOP]: CStates.FILTER_HANDLER,
      [CEvents.GO_BACK]: CStates.PROJECTION_EDITOR,
      [CEvents.HANDLER_COMPLETE]: CStates.PROJECTION_EDITOR,
    },
  },
};
