/// <reference types="./schema.d.mts" />
import * as $crypto from "../../../gleam_crypto/gleam/crypto.mjs";
import * as $json from "../../../gleam_json/gleam/json.mjs";
import * as $bit_array from "../../../gleam_stdlib/gleam/bit_array.mjs";
import * as $list from "../../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../../gleam_stdlib/gleam/option.mjs";
import { None, Some } from "../../../gleam_stdlib/gleam/option.mjs";
import { toList, CustomType as $CustomType } from "../../gleam.mjs";

export class Schema extends $CustomType {
  constructor(schema, vocabulary, id, comment, defs) {
    super();
    this.schema = schema;
    this.vocabulary = vocabulary;
    this.id = id;
    this.comment = comment;
    this.defs = defs;
  }
}

export class Type extends $CustomType {
  constructor(type_) {
    super();
    this.type_ = type_;
  }
}

export class Enum extends $CustomType {
  constructor(values, type_) {
    super();
    this.values = values;
    this.type_ = type_;
  }
}

export class Const extends $CustomType {
  constructor(value) {
    super();
    this.value = value;
  }
}

export class Nullable extends $CustomType {
  constructor(schema) {
    super();
    this.schema = schema;
  }
}

export class Optional extends $CustomType {
  constructor(schema) {
    super();
    this.schema = schema;
  }
}

export class Number extends $CustomType {
  constructor(minimum, maximum, exclusive_minimum, exclusive_maximum, multiple_of) {
    super();
    this.minimum = minimum;
    this.maximum = maximum;
    this.exclusive_minimum = exclusive_minimum;
    this.exclusive_maximum = exclusive_maximum;
    this.multiple_of = multiple_of;
  }
}

export class String extends $CustomType {
  constructor(min_length, max_length, pattern, format) {
    super();
    this.min_length = min_length;
    this.max_length = max_length;
    this.pattern = pattern;
    this.format = format;
  }
}

export class Array extends $CustomType {
  constructor(items) {
    super();
    this.items = items;
  }
}

export class DetailedArray extends $CustomType {
  constructor(items, prefix_items, min_items, max_items, unique_items, contains, min_contains, max_contains) {
    super();
    this.items = items;
    this.prefix_items = prefix_items;
    this.min_items = min_items;
    this.max_items = max_items;
    this.unique_items = unique_items;
    this.contains = contains;
    this.min_contains = min_contains;
    this.max_contains = max_contains;
  }
}

export class Object extends $CustomType {
  constructor(properties, additional_properties, required) {
    super();
    this.properties = properties;
    this.additional_properties = additional_properties;
    this.required = required;
  }
}

export class DetailedObject extends $CustomType {
  constructor(properties, pattern_properties, additional_properties, required, property_names, min_properties, max_properties) {
    super();
    this.properties = properties;
    this.pattern_properties = pattern_properties;
    this.additional_properties = additional_properties;
    this.required = required;
    this.property_names = property_names;
    this.min_properties = min_properties;
    this.max_properties = max_properties;
  }
}

export class AllOf extends $CustomType {
  constructor(schemas) {
    super();
    this.schemas = schemas;
  }
}

export class AnyOf extends $CustomType {
  constructor(schemas) {
    super();
    this.schemas = schemas;
  }
}

export class OneOf extends $CustomType {
  constructor(schemas) {
    super();
    this.schemas = schemas;
  }
}

export class Not extends $CustomType {
  constructor(schema) {
    super();
    this.schema = schema;
  }
}

export class Ref extends $CustomType {
  constructor(ref) {
    super();
    this.ref = ref;
  }
}

export class TrueValue extends $CustomType {}

export class FalseValue extends $CustomType {}

export class Null extends $CustomType {}

export class BooleanType extends $CustomType {}

export class ObjectType extends $CustomType {}

export class ArrayType extends $CustomType {}

export class NumberType extends $CustomType {}

export class StringType extends $CustomType {}

export class IntegerType extends $CustomType {}

export class Multiple extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class DateTime extends $CustomType {}

export class Date extends $CustomType {}

export class Time extends $CustomType {}

export class Duration extends $CustomType {}

export class Email extends $CustomType {}

export class IdnEmail extends $CustomType {}

export class Hostname extends $CustomType {}

export class IdnHostname extends $CustomType {}

export class Ipv4 extends $CustomType {}

export class Ipv6 extends $CustomType {}

export class Uri extends $CustomType {}

export class UriReference extends $CustomType {}

export class Iri extends $CustomType {}

export class IriReference extends $CustomType {}

export class UriTemplate extends $CustomType {}

export class JsonPointer extends $CustomType {}

export class RelativeJsonPointer extends $CustomType {}

export class Regex extends $CustomType {}

export function new_schema(definition, references) {
  return new Schema(definition, new None(), new None(), new None(), references);
}

export function type_constraint(type_) {
  return new Type(type_);
}

export function string_constraint(min_length, max_length, pattern, format) {
  return new String(min_length, max_length, pattern, format);
}

export function number_constraint(
  minimum,
  maximum,
  exclusive_minimum,
  exclusive_maximum,
  multiple_of
) {
  return new Number(
    minimum,
    maximum,
    exclusive_minimum,
    exclusive_maximum,
    multiple_of,
  );
}

export function map_ref(def, f) {
  if (def instanceof Ref) {
    let ref = def.ref;
    return new Ref(f(ref));
  } else if (def instanceof Array) {
    let items = def.items;
    return new Array($option.map(items, (i) => { return map_ref(i, f); }));
  } else if (def instanceof DetailedArray) {
    let items = def.items;
    let prefix_items = def.prefix_items;
    let min_items = def.min_items;
    let max_items = def.max_items;
    let unique_items = def.unique_items;
    let contains = def.contains;
    let min_contains = def.min_contains;
    let max_contains = def.max_contains;
    return new DetailedArray(
      $option.map(items, (i) => { return map_ref(i, f); }),
      $option.map(
        prefix_items,
        (items) => { return $list.map(items, (i) => { return map_ref(i, f); }); },
      ),
      min_items,
      max_items,
      unique_items,
      $option.map(contains, (c) => { return map_ref(c, f); }),
      min_contains,
      max_contains,
    );
  } else if (def instanceof Object) {
    let properties = def.properties;
    let additional_properties = def.additional_properties;
    let required = def.required;
    return new Object(
      $list.map(
        properties,
        (prop) => { return [prop[0], map_ref(prop[1], f)]; },
      ),
      additional_properties,
      required,
    );
  } else if (def instanceof DetailedObject) {
    let properties = def.properties;
    let pattern_properties = def.pattern_properties;
    let additional_properties = def.additional_properties;
    let required = def.required;
    let property_names = def.property_names;
    let min_properties = def.min_properties;
    let max_properties = def.max_properties;
    return new DetailedObject(
      $option.map(
        properties,
        (props) => {
          return $list.map(
            props,
            (prop) => { return [prop[0], map_ref(prop[1], f)]; },
          );
        },
      ),
      $option.map(
        pattern_properties,
        (patterns) => {
          return $list.map(
            patterns,
            (pattern) => { return [pattern[0], map_ref(pattern[1], f)]; },
          );
        },
      ),
      $option.map(
        additional_properties,
        (props) => { return map_ref(props, f); },
      ),
      required,
      $option.map(property_names, (names) => { return map_ref(names, f); }),
      min_properties,
      max_properties,
    );
  } else if (def instanceof AllOf) {
    let schemas = def.schemas;
    return new AllOf($list.map(schemas, (s) => { return map_ref(s, f); }));
  } else if (def instanceof AnyOf) {
    let schemas = def.schemas;
    return new AnyOf($list.map(schemas, (s) => { return map_ref(s, f); }));
  } else if (def instanceof OneOf) {
    let schemas = def.schemas;
    return new OneOf($list.map(schemas, (s) => { return map_ref(s, f); }));
  } else if (def instanceof Not) {
    let schema = def.schema;
    return new Not(map_ref(schema, f));
  } else if (def instanceof Nullable) {
    let schema = def.schema;
    return new Nullable(map_ref(schema, f));
  } else if (def instanceof Optional) {
    let schema = def.schema;
    return new Optional(map_ref(schema, f));
  } else {
    return def;
  }
}

function prepend_option(list, maybe, name, to_json) {
  if (maybe instanceof $option.Some) {
    let value = maybe[0];
    return $list.prepend(list, [name, to_json(value)]);
  } else {
    return list;
  }
}

function schema_type_to_json(type_) {
  if (type_ instanceof Null) {
    return $json.string("null");
  } else if (type_ instanceof BooleanType) {
    return $json.string("boolean");
  } else if (type_ instanceof ObjectType) {
    return $json.string("object");
  } else if (type_ instanceof ArrayType) {
    return $json.string("array");
  } else if (type_ instanceof NumberType) {
    return $json.string("number");
  } else if (type_ instanceof StringType) {
    return $json.string("string");
  } else if (type_ instanceof IntegerType) {
    return $json.string("integer");
  } else {
    let types = type_[0];
    return $json.array(types, (t) => { return schema_type_to_json(t); });
  }
}

function string_format_to_string(format) {
  if (format instanceof DateTime) {
    return "date-time";
  } else if (format instanceof Date) {
    return "date";
  } else if (format instanceof Time) {
    return "time";
  } else if (format instanceof Duration) {
    return "duration";
  } else if (format instanceof Email) {
    return "email";
  } else if (format instanceof IdnEmail) {
    return "idn-email";
  } else if (format instanceof Hostname) {
    return "hostname";
  } else if (format instanceof IdnHostname) {
    return "idn-hostname";
  } else if (format instanceof Ipv4) {
    return "ipv4";
  } else if (format instanceof Ipv6) {
    return "ipv6";
  } else if (format instanceof Uri) {
    return "uri";
  } else if (format instanceof UriReference) {
    return "uri-reference";
  } else if (format instanceof Iri) {
    return "iri";
  } else if (format instanceof IriReference) {
    return "iri-reference";
  } else if (format instanceof UriTemplate) {
    return "uri-template";
  } else if (format instanceof JsonPointer) {
    return "json-pointer";
  } else if (format instanceof RelativeJsonPointer) {
    return "relative-json-pointer";
  } else {
    return "regex";
  }
}

export const json_schema_version = "http://json-schema.org/draft-07/schema#";

function schema_definition_to_json_fields(loop$def, loop$make_type_nullable) {
  while (true) {
    let def = loop$def;
    let make_type_nullable = loop$make_type_nullable;
    let with_nullable_type = (type_) => {
      if (!make_type_nullable) {
        return schema_type_to_json(type_);
      } else {
        return schema_type_to_json(new Multiple(toList([type_, new Null()])));
      }
    };
    if (def instanceof Type) {
      let type_ = def.type_;
      return toList([["type", with_nullable_type(type_)]]);
    } else if (def instanceof Enum) {
      let values = def.values;
      let schema = def.type_;
      let _pipe = toList([["enum", $json.preprocessed_array(values)]]);
      return prepend_option(_pipe, schema, "type", with_nullable_type);
    } else if (def instanceof Const) {
      let value = def.value;
      return toList([["const", value]]);
    } else if (def instanceof Nullable) {
      let schema = def.schema;
      loop$def = schema;
      loop$make_type_nullable = true;
    } else if (def instanceof Optional) {
      let schema = def.schema;
      loop$def = schema;
      loop$make_type_nullable = make_type_nullable;
    } else if (def instanceof Number) {
      let minimum = def.minimum;
      let maximum = def.maximum;
      let exclusive_minimum = def.exclusive_minimum;
      let exclusive_maximum = def.exclusive_maximum;
      let multiple_of = def.multiple_of;
      let _pipe = toList([["type", with_nullable_type(new NumberType())]]);
      let _pipe$1 = prepend_option(_pipe, minimum, "minimum", $json.float);
      let _pipe$2 = prepend_option(_pipe$1, maximum, "maximum", $json.float);
      let _pipe$3 = prepend_option(
        _pipe$2,
        exclusive_minimum,
        "exclusiveMinimum",
        $json.float,
      );
      let _pipe$4 = prepend_option(
        _pipe$3,
        exclusive_maximum,
        "exclusiveMaximum",
        $json.float,
      );
      return prepend_option(_pipe$4, multiple_of, "multipleOf", $json.float);
    } else if (def instanceof String) {
      let min_length = def.min_length;
      let max_length = def.max_length;
      let pattern = def.pattern;
      let format = def.format;
      let _pipe = toList([["type", with_nullable_type(new StringType())]]);
      let _pipe$1 = prepend_option(_pipe, min_length, "minLength", $json.int);
      let _pipe$2 = prepend_option(_pipe$1, max_length, "maxLength", $json.int);
      let _pipe$3 = prepend_option(_pipe$2, pattern, "pattern", $json.string);
      return prepend_option(
        _pipe$3,
        format,
        "format",
        (f) => { return $json.string(string_format_to_string(f)); },
      );
    } else if (def instanceof Array) {
      let items = def.items;
      let _pipe = toList([["type", with_nullable_type(new ArrayType())]]);
      return prepend_option(
        _pipe,
        items,
        "items",
        (schema) => { return schema_definition_to_json(schema); },
      );
    } else if (def instanceof Object) {
      let properties = def.properties;
      let additional_properties = def.additional_properties;
      let required = def.required;
      let _pipe = toList([
        ["type", with_nullable_type(new ObjectType())],
        [
          "properties",
          $json.object(
            $list.map(
              properties,
              (prop) => { return [prop[0], schema_definition_to_json(prop[1])]; },
            ),
          ),
        ],
      ]);
      let _pipe$1 = prepend_option(
        _pipe,
        additional_properties,
        "additionalProperties",
        $json.bool,
      );
      return prepend_option(
        _pipe$1,
        required,
        "required",
        (_capture) => { return $json.array(_capture, $json.string); },
      );
    } else if (def instanceof DetailedArray) {
      let items = def.items;
      let prefix_items = def.prefix_items;
      let min_items = def.min_items;
      let max_items = def.max_items;
      let unique_items = def.unique_items;
      let contains = def.contains;
      let min_contains = def.min_contains;
      let max_contains = def.max_contains;
      let _pipe = toList([["type", with_nullable_type(new ArrayType())]]);
      let _pipe$1 = prepend_option(
        _pipe,
        items,
        "items",
        (schema) => { return schema_definition_to_json(schema); },
      );
      let _pipe$2 = prepend_option(
        _pipe$1,
        prefix_items,
        "prefixItems",
        (schemas) => { return $json.array(schemas, schema_definition_to_json); },
      );
      let _pipe$3 = prepend_option(_pipe$2, min_items, "minItems", $json.int);
      let _pipe$4 = prepend_option(_pipe$3, max_items, "maxItems", $json.int);
      let _pipe$5 = prepend_option(
        _pipe$4,
        unique_items,
        "uniqueItems",
        $json.bool,
      );
      let _pipe$6 = prepend_option(
        _pipe$5,
        contains,
        "contains",
        (schema) => { return schema_definition_to_json(schema); },
      );
      let _pipe$7 = prepend_option(
        _pipe$6,
        min_contains,
        "minContains",
        $json.int,
      );
      return prepend_option(_pipe$7, max_contains, "maxContains", $json.int);
    } else if (def instanceof DetailedObject) {
      let properties = def.properties;
      let pattern_properties = def.pattern_properties;
      let additional_properties = def.additional_properties;
      let required = def.required;
      let property_names = def.property_names;
      let min_properties = def.min_properties;
      let max_properties = def.max_properties;
      let _pipe = toList([["type", with_nullable_type(new ObjectType())]]);
      let _pipe$1 = prepend_option(
        _pipe,
        properties,
        "properties",
        (props) => {
          return $json.object(
            $list.map(
              props,
              (prop) => { return [prop[0], schema_definition_to_json(prop[1])]; },
            ),
          );
        },
      );
      let _pipe$2 = prepend_option(
        _pipe$1,
        pattern_properties,
        "patternProperties",
        (patterns) => {
          return $json.object(
            $list.map(
              patterns,
              (pattern) => {
                return [pattern[0], schema_definition_to_json(pattern[1])];
              },
            ),
          );
        },
      );
      let _pipe$3 = prepend_option(
        _pipe$2,
        additional_properties,
        "additionalProperties",
        (schema) => { return schema_definition_to_json(schema); },
      );
      let _pipe$4 = prepend_option(
        _pipe$3,
        required,
        "required",
        (reqs) => { return $json.array(reqs, $json.string); },
      );
      let _pipe$5 = prepend_option(
        _pipe$4,
        property_names,
        "propertyNames",
        (schema) => { return schema_definition_to_json(schema); },
      );
      let _pipe$6 = prepend_option(
        _pipe$5,
        min_properties,
        "minProperties",
        $json.int,
      );
      return prepend_option(_pipe$6, max_properties, "maxProperties", $json.int);
    } else if (def instanceof AllOf) {
      let schemas = def.schemas;
      return toList([["allOf", $json.array(schemas, schema_definition_to_json)]]);
    } else if (def instanceof AnyOf) {
      let schemas = def.schemas;
      return toList([["anyOf", $json.array(schemas, schema_definition_to_json)]]);
    } else if (def instanceof OneOf) {
      let schemas = def.schemas;
      return toList([["oneOf", $json.array(schemas, schema_definition_to_json)]]);
    } else if (def instanceof Not) {
      let schema = def.schema;
      return toList([["not", schema_definition_to_json(schema)]]);
    } else if (def instanceof Ref) {
      let ref = def.ref;
      if (!make_type_nullable) {
        return toList([["$ref", $json.string(ref)]]);
      } else {
        loop$def = new AnyOf(toList([new Ref(ref), new Type(new Null())]));
        loop$make_type_nullable = false;
      }
    } else if (def instanceof TrueValue) {
      return toList([["type", $json.bool(true)]]);
    } else {
      return toList([["type", $json.bool(false)]]);
    }
  }
}

function schema_definition_to_json(def) {
  return $json.object(schema_definition_to_json_fields(def, false));
}

export function to_json(schema) {
  let fields = (() => {
    let _pipe = toList([["$schema", $json.string(json_schema_version)]]);
    let _pipe$1 = prepend_option(
      _pipe,
      schema.vocabulary,
      "$vocabulary",
      (vocab) => { return $json.array(vocab, $json.string); },
    );
    let _pipe$2 = prepend_option(_pipe$1, schema.id, "$id", $json.string);
    let _pipe$3 = prepend_option(
      _pipe$2,
      schema.comment,
      "$comment",
      $json.string,
    );
    return prepend_option(
      _pipe$3,
      schema.defs,
      "$defs",
      (defs) => {
        return $json.object(
          $list.map(
            defs,
            (def) => { return [def[0], schema_definition_to_json(def[1])]; },
          ),
        );
      },
    );
  })();
  let schema_fields = schema_definition_to_json_fields(schema.schema, false);
  let fields$1 = $list.append(fields, schema_fields);
  return $json.object(fields$1);
}

export function to_json_string(schema) {
  let _pipe = schema;
  let _pipe$1 = to_json(_pipe);
  return $json.to_string(_pipe$1);
}

export function hash_schema_definition(def) {
  let _pipe = def;
  let _pipe$1 = schema_definition_to_json(_pipe);
  let _pipe$2 = $json.to_string(_pipe$1);
  let _pipe$3 = $bit_array.from_string(_pipe$2);
  let _pipe$4 = ((_capture) => {
    return $crypto.hash(new $crypto.Sha1(), _capture);
  })(_pipe$3);
  return $bit_array.base16_encode(_pipe$4);
}
