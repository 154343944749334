import { exhaustiveCheck } from "@bumblebee/common/utils";
import {
  AddMeasure,
  aggregation_to_string,
  BoolValue,
  DomainElement$,
  DomainProperty,
  DomainTimeline,
  Expression$,
  FilterCondition$,
  FilterDomain,
  FilterMeasure,
  FloatValue,
  IntValue,
  MeasureAgg,
  MeasureElement$,
  MeasureOneToOne,
  ProjectionEdit$,
  Property,
  PropertyReference,
  StringValue,
  Value$,
} from "@bumblebee/core/api/projection_edits";
import * as buzz from "@bumblebee/core/buzz_model";
import { BuzzModel, Entity, entity_to_json } from "@bumblebee/core/buzz_model";

import { CStates } from "@/lib/state-machine";

// TODO: this check should be more robust
export function isSameEntity(a: Entity, b: Entity): boolean {
  return (
    JSON.stringify(entity_to_json(a)) === JSON.stringify(entity_to_json(b))
  );
}

export function isSameProperty(a: buzz.Property, b: buzz.Property): boolean {
  return (
    JSON.stringify(buzz.property_to_json(a)) ===
    JSON.stringify(buzz.property_to_json(b))
  );
}

export function isReferenceSameEntityProperty(
  a: PropertyReference,
  b: buzz.Entity,
  c: buzz.Property,
): boolean {
  return (
    a.entity_name === b.name &&
    a.property_name === c.name &&
    b.properties.toArray().some((p) => isSameProperty(c, p))
  );
}

export function isSamePropertyReference(
  a: PropertyReference,
  b: PropertyReference,
): boolean {
  return a.entity_name == b.entity_name && a.property_name === b.property_name;
}

// TODO: delete this, use constructor or factory
export function propertyToPropertyReference(
  entity: buzz.Entity,
  property: buzz.Property,
): PropertyReference {
  return new PropertyReference(entity.name, property.name);
}

// TODO: delete this, use constructor or factory
export function propertiesToPropertyReferences(
  entity: Entity,
): PropertyReference[] {
  return Object.values(entity.properties).map((properties) =>
    propertyToPropertyReference(entity, properties),
  );
}

// unique key for react rendering optimization, not for identity or equality checking
export const getKeyForElement = (
  element: DomainElement$ | MeasureElement$,
): string => {
  switch (true) {
    case element instanceof DomainProperty:
      return `DomainElement-${element.property.entity_name}-${element.property.property_name}`;
    case element instanceof DomainTimeline:
      return `DomainTimeline-${element.start}-${element.end}`;
    case element instanceof MeasureOneToOne:
      return `MeasureOneToOne-${element.property.entity_name}-${element.property.property_name}`;
    case element instanceof MeasureAgg:
      return `MeasureAgg-${element.property.entity_name}-${element.property.property_name}`;
    default:
      console.error("unhandled case", element);
      exhaustiveCheck(element);
  }
};

// unique key for react rendering optimization, not for identity or equality checking
export const getKeyForExpression = (expression: Expression$): string => {
  switch (true) {
    case expression instanceof Property:
      return `Property-${expression.property.entity_name}-${expression.property.property_name}`;
    default:
      throw new Error(
        `unhandled case in getKeyForExpression: ${expression.constructor.name}`,
      );
  }
};

// unique key for react rendering optimization, not for identity or equality checking
export const getKeyForProjectionEdit = (edit: ProjectionEdit$): string => {
  switch (true) {
    case edit instanceof AddMeasure:
      return `AddMeasure-${getKeyForExpression(edit.expression)}-${aggregation_to_string(edit.aggregation)}`;
    default:
      throw new Error(
        `unhandled case in getKeyForProjectionEdit: ${edit.constructor.name}`,
      );
  }
};

export const pathFromCState = (state: CStates): `/workbook/${CStates}` => {
  return `/workbook/${state}`;
};

export const entityIncomingRelationships = (
  entity: Entity,
  model: BuzzModel,
): Entity[] => {
  const relationships = model.relationships.toArray().filter((rel) => {
    return isSameEntity(rel.to.entity, entity);
  });
  return relationships.map((rel) => rel.from.entity);
};

export const entityOutgoingRelationships = (
  entity: Entity,
  model: BuzzModel,
): Entity[] => {
  const relationships = model.relationships.toArray().filter((rel) => {
    return isSameEntity(rel.from.entity, entity);
  });
  return relationships.map((rel) => rel.to.entity);
};

export const propertyReferencesHasProperty = (
  references: PropertyReference[],
  entity: Entity,
  property: buzz.Property,
) =>
  references.some((ref) =>
    isReferenceSameEntityProperty(ref, entity, property),
  );

export const domainPropertiesHasProperty = (
  dps: DomainProperty[],
  entity: buzz.Entity,
  property: buzz.Property,
) =>
  dps.some((p) => isReferenceSameEntityProperty(p.property, entity, property));

export const propertyReferenceFromDomainElement = (
  de: DomainElement$,
): PropertyReference => {
  switch (true) {
    case de instanceof DomainProperty:
      return de.property;
    case de instanceof DomainTimeline:
      throw new Error("DomainTimeline does not have a property reference");
    default:
      exhaustiveCheck(de);
  }
};

export const propertyReferenceFromMeasureElement = (
  me: MeasureElement$,
): PropertyReference => {
  switch (true) {
    case me instanceof MeasureOneToOne:
    case me instanceof MeasureAgg:
      return me.property;
    default:
      exhaustiveCheck(me);
  }
};

export const propertyReferenceFromFilterCondition = (
  fc: FilterCondition$,
): PropertyReference => {
  switch (true) {
    case fc instanceof FilterDomain:
      return propertyReferenceFromDomainElement(fc.domain);
    case fc instanceof FilterMeasure:
      return propertyReferenceFromMeasureElement(fc.measure);
    default:
      exhaustiveCheck(fc);
  }
};

// `value` passed in is an instance of Value$ class, but not one constructed with
// the value of `literal`
export const filterValueFromLiteral = (
  value: Value$,
  literal: string,
): Value$ => {
  switch (true) {
    case value instanceof StringValue:
      return new StringValue(literal);
    case value instanceof BoolValue:
      return new BoolValue(Boolean(literal));
    case value instanceof IntValue:
      return new IntValue(parseInt(literal));
    case value instanceof FloatValue:
      return new FloatValue(parseFloat(literal));
    default:
      exhaustiveCheck(value);
  }
};
