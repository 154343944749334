import { CustomType, Result } from "@bumblebee/core/gleam";
import { lazy_unwrap, unwrap_error } from "@bumblebee/core/gleam/result";
export const assertEnv = (env) => {
    const unsetVars = Object.entries(env).filter(([, v]) => v == null);
    if (unsetVars.length > 0) {
        throw new Error(`The following environment variables are required but are not set: [${unsetVars.map(([name]) => name).join(", ")}]. Ensure you have created a .env file (check env.example)`);
    }
    return env;
};
export function exhaustiveCheck(
// eslint-disable-next-line @typescript-eslint/no-unused-vars
_) {
    throw new Error("Exhaustive check failed. Possible fallthrough case in switch statement?");
}
export const fromResult = (r) => {
    const unwrapped = lazy_unwrap(r, () => {
        const err = unwrap_error(r, null);
        throw new Error(err?.constructor.name || "Unknown error in fromResult");
    });
    return unwrapped;
};
// extract values from a tuple type generated by gleam, which requires
// steamrolling the type error on the constructor parameters. This is useful
// for getting a list of all the class constructors, but since they
// constructors were called with no arguments, they are empty/nil instances of
// that class, so not useful for purposes other than enumeration of a tuple
// representing all union type members.
//
// Example:
// import { all_expressions } from "@bumblebee/core/api/projection_edits";
// ...
// const expressionClassNames = fromTuple(all_expressions).map(e => e.constructor.name);
//
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fromTuple = (fns) => {
    return fns.map((fn) => fn());
};
