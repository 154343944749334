/// <reference types="./projection_edits.d.mts" />
import * as $json from "../../gleam_json/gleam/json.mjs";
import * as $bool from "../../gleam_stdlib/gleam/bool.mjs";
import * as $float from "../../gleam_stdlib/gleam/float.mjs";
import * as $int from "../../gleam_stdlib/gleam/int.mjs";
import * as $list from "../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import * as $string from "../../gleam_stdlib/gleam/string.mjs";
import * as $blueprint from "../../json_blueprint/json/blueprint.mjs";
import { Ok, Error, toList, CustomType as $CustomType } from "../gleam.mjs";

export class ProjectionEditsResult extends $CustomType {
  constructor(edits, error) {
    super();
    this.edits = edits;
    this.error = error;
  }
}

export class Property extends $CustomType {
  constructor(property) {
    super();
    this.property = property;
  }
}

export class Literal extends $CustomType {
  constructor(value) {
    super();
    this.value = value;
  }
}

export class Add extends $CustomType {
  constructor(left, right) {
    super();
    this.left = left;
    this.right = right;
  }
}

export class Subtract extends $CustomType {
  constructor(left, right) {
    super();
    this.left = left;
    this.right = right;
  }
}

export class Multiply extends $CustomType {
  constructor(left, right) {
    super();
    this.left = left;
    this.right = right;
  }
}

export class Divide extends $CustomType {
  constructor(left, right) {
    super();
    this.left = left;
    this.right = right;
  }
}

export class AddDomainElement extends $CustomType {
  constructor(element) {
    super();
    this.element = element;
  }
}

export class AddMeasure extends $CustomType {
  constructor(expression, aggregation) {
    super();
    this.expression = expression;
    this.aggregation = aggregation;
  }
}

export class AddFilter extends $CustomType {
  constructor(condition) {
    super();
    this.condition = condition;
  }
}

export class AddOrderBy extends $CustomType {
  constructor(condition) {
    super();
    this.condition = condition;
  }
}

export class DomainProperty extends $CustomType {
  constructor(property) {
    super();
    this.property = property;
  }
}

export class DomainTimeline extends $CustomType {
  constructor(start, end) {
    super();
    this.start = start;
    this.end = end;
  }
}

export class PropertyReference extends $CustomType {
  constructor(entity_name, property_name) {
    super();
    this.entity_name = entity_name;
    this.property_name = property_name;
  }
}

export class Sum extends $CustomType {}

export class Min extends $CustomType {}

export class Max extends $CustomType {}

export class Avg extends $CustomType {}

export class Count extends $CustomType {}

export class MeasureOneToOne extends $CustomType {
  constructor(property) {
    super();
    this.property = property;
  }
}

export class MeasureAgg extends $CustomType {
  constructor(aggregation, property) {
    super();
    this.aggregation = aggregation;
    this.property = property;
  }
}

export class FilterDomain extends $CustomType {
  constructor(domain, operator, value) {
    super();
    this.domain = domain;
    this.operator = operator;
    this.value = value;
  }
}

export class FilterMeasure extends $CustomType {
  constructor(measure, operator, value) {
    super();
    this.measure = measure;
    this.operator = operator;
    this.value = value;
  }
}

export class Equal extends $CustomType {}

export class NotEqual extends $CustomType {}

export class GreaterThan extends $CustomType {}

export class LessThan extends $CustomType {}

export class In extends $CustomType {}

export class Like extends $CustomType {}

export class IntValue extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class StringValue extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class FloatValue extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class BoolValue extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class Ascending extends $CustomType {}

export class Descending extends $CustomType {}

export class OrderDomainBy extends $CustomType {
  constructor(domain, order) {
    super();
    this.domain = domain;
    this.order = order;
  }
}

export class OrderMeasureBy extends $CustomType {
  constructor(measure, order) {
    super();
    this.measure = measure;
    this.order = order;
  }
}

export function aggregation_to_string(agg) {
  if (agg instanceof Sum) {
    return "Sum";
  } else if (agg instanceof Min) {
    return "Min";
  } else if (agg instanceof Max) {
    return "Max";
  } else if (agg instanceof Avg) {
    return "Avg";
  } else {
    return "Count";
  }
}

export function operator_to_string(op) {
  if (op instanceof Equal) {
    return "Equal";
  } else if (op instanceof NotEqual) {
    return "NotEqual";
  } else if (op instanceof GreaterThan) {
    return "GreaterThan";
  } else if (op instanceof LessThan) {
    return "LessThan";
  } else if (op instanceof In) {
    return "In";
  } else {
    return "Like";
  }
}

export function filter_condition_to_string(cond) {
  if (cond instanceof FilterDomain) {
    return "FilterDomain";
  } else {
    return "FilterMeasure";
  }
}

export function order_to_string(order) {
  if (order instanceof Ascending) {
    return "Ascending";
  } else {
    return "Descending";
  }
}

export function order_by_to_string(order_by) {
  if (order_by instanceof OrderDomainBy) {
    return "OrderDomainBy";
  } else {
    return "OrderMeasureBy";
  }
}

export function value_to_string(value) {
  if (value instanceof IntValue) {
    let v = value[0];
    return ("IntValue(" + $int.to_string(v)) + ")";
  } else if (value instanceof StringValue) {
    let v = value[0];
    return ("StringValue(" + v) + ")";
  } else if (value instanceof FloatValue) {
    let v = value[0];
    return ("FloatValue(" + $float.to_string(v)) + ")";
  } else {
    let v = value[0];
    return ("BoolValue(" + $bool.to_string(v)) + ")";
  }
}

export function property_reference_decoder() {
  let _pipe = $blueprint.union_type_decoder(
    toList([
      [
        "property_reference",
        $blueprint.decode2(
          (var0, var1) => { return new PropertyReference(var0, var1); },
          $blueprint.field("entity_name", $blueprint.string()),
          $blueprint.field("property_name", $blueprint.string()),
        ),
      ],
    ]),
  );
  return $blueprint.reuse_decoder(_pipe);
}

export function domain_element_decoder() {
  let _pipe = $blueprint.union_type_decoder(
    toList([
      [
        "domain_property",
        $blueprint.decode1(
          (var0) => { return new DomainProperty(var0); },
          $blueprint.field("property", property_reference_decoder()),
        ),
      ],
      [
        "domain_timeline",
        $blueprint.decode2(
          (var0, var1) => { return new DomainTimeline(var0, var1); },
          $blueprint.field("start", $blueprint.string()),
          $blueprint.field("end", $blueprint.string()),
        ),
      ],
    ]),
  );
  return $blueprint.reuse_decoder(_pipe);
}

export function property_reference_encoder(ref) {
  return $blueprint.union_type_encoder(
    ref,
    (ref) => {
      {
        let entity_name = ref.entity_name;
        let property_name = ref.property_name;
        return [
          "property_reference",
          $json.object(
            toList([
              ["entity_name", $json.string(entity_name)],
              ["property_name", $json.string(property_name)],
            ]),
          ),
        ];
      }
    },
  );
}

export function domain_element_encoder(element) {
  return $blueprint.union_type_encoder(
    element,
    (element) => {
      if (element instanceof DomainProperty) {
        let property = element.property;
        return [
          "domain_property",
          $json.object(
            toList([["property", property_reference_encoder(property)]]),
          ),
        ];
      } else {
        let start = element.start;
        let end = element.end;
        return [
          "domain_timeline",
          $json.object(
            toList([["start", $json.string(start)], ["end", $json.string(end)]]),
          ),
        ];
      }
    },
  );
}

export function aggregation_decoder() {
  let _pipe = $blueprint.enum_type_decoder(
    toList([
      ["sum", new Sum()],
      ["min", new Min()],
      ["max", new Max()],
      ["avg", new Avg()],
      ["count", new Count()],
    ]),
  );
  return $blueprint.reuse_decoder(_pipe);
}

export function aggregation_encoder(agg) {
  return $blueprint.enum_type_encoder(
    agg,
    (agg) => {
      if (agg instanceof Sum) {
        return "sum";
      } else if (agg instanceof Min) {
        return "min";
      } else if (agg instanceof Max) {
        return "max";
      } else if (agg instanceof Avg) {
        return "avg";
      } else {
        return "count";
      }
    },
  );
}

export function measure_element_decoder() {
  let _pipe = $blueprint.union_type_decoder(
    toList([
      [
        "measure_one_to_one",
        $blueprint.decode1(
          (var0) => { return new MeasureOneToOne(var0); },
          $blueprint.field("property", property_reference_decoder()),
        ),
      ],
      [
        "measure_agg",
        $blueprint.decode2(
          (var0, var1) => { return new MeasureAgg(var0, var1); },
          $blueprint.field("aggregation", aggregation_decoder()),
          $blueprint.field("property", property_reference_decoder()),
        ),
      ],
    ]),
  );
  return $blueprint.reuse_decoder(_pipe);
}

export function measure_element_encoder(element) {
  return $blueprint.union_type_encoder(
    element,
    (element) => {
      if (element instanceof MeasureOneToOne) {
        let property = element.property;
        return [
          "measure_one_to_one",
          $json.object(
            toList([["property", property_reference_encoder(property)]]),
          ),
        ];
      } else {
        let aggregation = element.aggregation;
        let property = element.property;
        return [
          "measure_agg",
          $json.object(
            toList([
              ["aggregation", aggregation_encoder(aggregation)],
              ["property", property_reference_encoder(property)],
            ]),
          ),
        ];
      }
    },
  );
}

export function operator_decoder() {
  let _pipe = $blueprint.enum_type_decoder(
    toList([
      ["eq", new Equal()],
      ["neq", new NotEqual()],
      ["gt", new GreaterThan()],
      ["lt", new LessThan()],
      ["in", new In()],
      ["like", new Like()],
    ]),
  );
  return $blueprint.reuse_decoder(_pipe);
}

export function operator_encoder(op) {
  return $blueprint.enum_type_encoder(
    op,
    (op) => {
      if (op instanceof Equal) {
        return "eq";
      } else if (op instanceof NotEqual) {
        return "neq";
      } else if (op instanceof GreaterThan) {
        return "gt";
      } else if (op instanceof LessThan) {
        return "lt";
      } else if (op instanceof In) {
        return "in";
      } else {
        return "like";
      }
    },
  );
}

export function value_decoder() {
  let _pipe = $blueprint.union_type_decoder(
    toList([
      [
        "int_value",
        $blueprint.decode1(
          (var0) => { return new IntValue(var0); },
          $blueprint.field("value", $blueprint.int()),
        ),
      ],
      [
        "string_value",
        $blueprint.decode1(
          (var0) => { return new StringValue(var0); },
          $blueprint.field("value", $blueprint.string()),
        ),
      ],
      [
        "float_value",
        $blueprint.decode1(
          (var0) => { return new FloatValue(var0); },
          $blueprint.field("value", $blueprint.float()),
        ),
      ],
      [
        "bool_value",
        $blueprint.decode1(
          (var0) => { return new BoolValue(var0); },
          $blueprint.field("value", $blueprint.bool()),
        ),
      ],
    ]),
  );
  return $blueprint.reuse_decoder(_pipe);
}

export function expression_decoder() {
  let _pipe = $blueprint.union_type_decoder(
    toList([
      [
        "property",
        $blueprint.decode1(
          (var0) => { return new Property(var0); },
          $blueprint.field("property", property_reference_decoder()),
        ),
      ],
      [
        "literal",
        $blueprint.decode1(
          (var0) => { return new Literal(var0); },
          $blueprint.field("value", value_decoder()),
        ),
      ],
      [
        "add",
        $blueprint.decode2(
          (var0, var1) => { return new Add(var0, var1); },
          $blueprint.field("left", $blueprint.self_decoder(expression_decoder)),
          $blueprint.field("right", $blueprint.self_decoder(expression_decoder)),
        ),
      ],
      [
        "subtract",
        $blueprint.decode2(
          (var0, var1) => { return new Subtract(var0, var1); },
          $blueprint.field("left", $blueprint.self_decoder(expression_decoder)),
          $blueprint.field("right", $blueprint.self_decoder(expression_decoder)),
        ),
      ],
      [
        "multiply",
        $blueprint.decode2(
          (var0, var1) => { return new Multiply(var0, var1); },
          $blueprint.field("left", $blueprint.self_decoder(expression_decoder)),
          $blueprint.field("right", $blueprint.self_decoder(expression_decoder)),
        ),
      ],
      [
        "divide",
        $blueprint.decode2(
          (var0, var1) => { return new Divide(var0, var1); },
          $blueprint.field("left", $blueprint.self_decoder(expression_decoder)),
          $blueprint.field("right", $blueprint.self_decoder(expression_decoder)),
        ),
      ],
    ]),
  );
  return $blueprint.reuse_decoder(_pipe);
}

export function filter_condition_decoder() {
  return $blueprint.union_type_decoder(
    toList([
      [
        "filter_domain",
        $blueprint.decode3(
          (var0, var1, var2) => { return new FilterDomain(var0, var1, var2); },
          $blueprint.field("domain", domain_element_decoder()),
          $blueprint.field("operator", operator_decoder()),
          $blueprint.field("value", value_decoder()),
        ),
      ],
      [
        "filter_measure",
        $blueprint.decode3(
          (var0, var1, var2) => { return new FilterMeasure(var0, var1, var2); },
          $blueprint.field("measure", measure_element_decoder()),
          $blueprint.field("operator", operator_decoder()),
          $blueprint.field("value", value_decoder()),
        ),
      ],
    ]),
  );
}

export function value_encoder(value) {
  return $blueprint.union_type_encoder(
    value,
    (value) => {
      if (value instanceof IntValue) {
        let v = value[0];
        return ["int_value", $json.object(toList([["value", $json.int(v)]]))];
      } else if (value instanceof StringValue) {
        let v = value[0];
        return [
          "string_value",
          $json.object(toList([["value", $json.string(v)]])),
        ];
      } else if (value instanceof FloatValue) {
        let v = value[0];
        return [
          "float_value",
          $json.object(toList([["value", $json.float(v)]])),
        ];
      } else {
        let v = value[0];
        return ["bool_value", $json.object(toList([["value", $json.bool(v)]]))];
      }
    },
  );
}

export function expression_encoder(expr) {
  return $blueprint.union_type_encoder(
    expr,
    (expr) => {
      if (expr instanceof Property) {
        let property = expr.property;
        return [
          "property",
          $json.object(
            toList([["property", property_reference_encoder(property)]]),
          ),
        ];
      } else if (expr instanceof Literal) {
        let value = expr.value;
        return [
          "literal",
          $json.object(toList([["value", value_encoder(value)]])),
        ];
      } else if (expr instanceof Add) {
        let left = expr.left;
        let right = expr.right;
        return [
          "add",
          $json.object(
            toList([
              ["left", expression_encoder(left)],
              ["right", expression_encoder(right)],
            ]),
          ),
        ];
      } else if (expr instanceof Subtract) {
        let left = expr.left;
        let right = expr.right;
        return [
          "subtract",
          $json.object(
            toList([
              ["left", expression_encoder(left)],
              ["right", expression_encoder(right)],
            ]),
          ),
        ];
      } else if (expr instanceof Multiply) {
        let left = expr.left;
        let right = expr.right;
        return [
          "multiply",
          $json.object(
            toList([
              ["left", expression_encoder(left)],
              ["right", expression_encoder(right)],
            ]),
          ),
        ];
      } else {
        let left = expr.left;
        let right = expr.right;
        return [
          "divide",
          $json.object(
            toList([
              ["left", expression_encoder(left)],
              ["right", expression_encoder(right)],
            ]),
          ),
        ];
      }
    },
  );
}

export function filter_condition_encoder(condition) {
  return $blueprint.union_type_encoder(
    condition,
    (condition) => {
      if (condition instanceof FilterDomain) {
        let domain = condition.domain;
        let operator = condition.operator;
        let value = condition.value;
        return [
          "filter_domain",
          $json.object(
            toList([
              ["domain", domain_element_encoder(domain)],
              ["operator", operator_encoder(operator)],
              ["value", value_encoder(value)],
            ]),
          ),
        ];
      } else {
        let measure = condition.measure;
        let operator = condition.operator;
        let value = condition.value;
        return [
          "filter_measure",
          $json.object(
            toList([
              ["measure", measure_element_encoder(measure)],
              ["operator", operator_encoder(operator)],
              ["value", value_encoder(value)],
            ]),
          ),
        ];
      }
    },
  );
}

export function order_decoder() {
  let _pipe = $blueprint.enum_type_decoder(
    toList([["asc", new Ascending()], ["desc", new Descending()]]),
  );
  return $blueprint.reuse_decoder(_pipe);
}

export function order_by_decoder() {
  return $blueprint.union_type_decoder(
    toList([
      [
        "order_domain_by",
        $blueprint.decode2(
          (var0, var1) => { return new OrderDomainBy(var0, var1); },
          $blueprint.field("domain", domain_element_decoder()),
          $blueprint.field("order", order_decoder()),
        ),
      ],
      [
        "order_measure_by",
        $blueprint.decode2(
          (var0, var1) => { return new OrderMeasureBy(var0, var1); },
          $blueprint.field("measure", measure_element_decoder()),
          $blueprint.field("order", order_decoder()),
        ),
      ],
    ]),
  );
}

export function projection_edit_decoder() {
  return $blueprint.union_type_decoder(
    toList([
      [
        "add_domain_element",
        $blueprint.decode1(
          (var0) => { return new AddDomainElement(var0); },
          $blueprint.field("element", domain_element_decoder()),
        ),
      ],
      [
        "add_measure",
        $blueprint.decode2(
          (var0, var1) => { return new AddMeasure(var0, var1); },
          $blueprint.field("expression", expression_decoder()),
          $blueprint.optional_field("aggregation", aggregation_decoder()),
        ),
      ],
      [
        "add_filter",
        $blueprint.decode1(
          (var0) => { return new AddFilter(var0); },
          $blueprint.field("condition", filter_condition_decoder()),
        ),
      ],
      [
        "add_order_by",
        $blueprint.decode1(
          (var0) => { return new AddOrderBy(var0); },
          $blueprint.field("condition", order_by_decoder()),
        ),
      ],
    ]),
  );
}

export function projection_edits_decoder() {
  return $blueprint.list(projection_edit_decoder());
}

export function api_projection_edits_from_json(json_string) {
  return $blueprint.decode(projection_edits_decoder(), json_string);
}

export function projection_edits_result_decoder() {
  return $blueprint.union_type_decoder(
    toList([
      [
        "projection_edits_result",
        $blueprint.decode2(
          (var0, var1) => { return new ProjectionEditsResult(var0, var1); },
          $blueprint.field("edits", $blueprint.list(projection_edit_decoder())),
          $blueprint.optional_field("error", $blueprint.string()),
        ),
      ],
    ]),
  );
}

export function api_projection_edits_result_from_json(json_string) {
  return $blueprint.decode(projection_edits_result_decoder(), json_string);
}

export function api_projection_edits_schema_to_json() {
  let _pipe = projection_edits_result_decoder();
  let _pipe$1 = $blueprint.generate_json_schema(_pipe);
  return $json.to_string(_pipe$1);
}

export function order_encoder(order) {
  return $blueprint.enum_type_encoder(
    order,
    (order) => {
      if (order instanceof Ascending) {
        return "asc";
      } else {
        return "desc";
      }
    },
  );
}

export function order_by_encoder(order_by) {
  return $blueprint.union_type_encoder(
    order_by,
    (order_by) => {
      if (order_by instanceof OrderDomainBy) {
        let domain = order_by.domain;
        let order = order_by.order;
        return [
          "order_domain_by",
          $json.object(
            toList([
              ["domain", domain_element_encoder(domain)],
              ["order", order_encoder(order)],
            ]),
          ),
        ];
      } else {
        let measure = order_by.measure;
        let order = order_by.order;
        return [
          "order_measure_by",
          $json.object(
            toList([
              ["measure", measure_element_encoder(measure)],
              ["order", order_encoder(order)],
            ]),
          ),
        ];
      }
    },
  );
}

export function projection_edit_encoder(edit) {
  return $blueprint.union_type_encoder(
    edit,
    (edit) => {
      if (edit instanceof AddDomainElement) {
        let element = edit.element;
        return [
          "add_domain_element",
          $json.object(toList([["element", domain_element_encoder(element)]])),
        ];
      } else if (edit instanceof AddMeasure) {
        let property = edit.expression;
        let aggregation = edit.aggregation;
        return [
          "add_measure",
          $json.object(
            (() => {
              let _pipe = toList([["expression", expression_encoder(property)]]);
              return $blueprint.encode_optional_field(
                _pipe,
                "aggregation",
                aggregation,
                aggregation_encoder,
              );
            })(),
          ),
        ];
      } else if (edit instanceof AddFilter) {
        let condition = edit.condition;
        return [
          "add_filter",
          $json.object(
            toList([["condition", filter_condition_encoder(condition)]]),
          ),
        ];
      } else {
        let condition = edit.condition;
        return [
          "add_order_by",
          $json.object(toList([["condition", order_by_encoder(condition)]])),
        ];
      }
    },
  );
}

export function projection_edits_encoder(result) {
  return $json.array(result, projection_edit_encoder);
}

export function projection_edits_result_encoder(result) {
  return $blueprint.union_type_encoder(
    result,
    (result) => {
      {
        let edits = result.edits;
        let error = result.error;
        return [
          "projection_edits_result",
          $json.object(
            (() => {
              let _pipe = toList([
                ["edits", $json.array(edits, projection_edit_encoder)],
              ]);
              return $blueprint.encode_optional_field(
                _pipe,
                "error",
                error,
                $json.string,
              );
            })(),
          ),
        ];
      }
    },
  );
}

export const all_aggregations = /* @__PURE__ */ toList([
  /* @__PURE__ */ new Sum(),
  /* @__PURE__ */ new Min(),
  /* @__PURE__ */ new Max(),
  /* @__PURE__ */ new Avg(),
  /* @__PURE__ */ new Count(),
]);

export function parse_aggregation(input) {
  if (input === "Sum") {
    return new Ok(new Sum());
  } else if (input === "Min") {
    return new Ok(new Min());
  } else if (input === "Max") {
    return new Ok(new Max());
  } else if (input === "Avg") {
    return new Ok(new Avg());
  } else if (input === "Count") {
    return new Ok(new Count());
  } else {
    let list_of_aggs = (() => {
      let _pipe = all_aggregations;
      let _pipe$1 = $list.map(_pipe, aggregation_to_string);
      return $string.join(_pipe$1, ", ");
    })();
    return new Error("Invalid aggregation, it shoud be one of: " + list_of_aggs);
  }
}

export const all_operators = /* @__PURE__ */ toList([
  /* @__PURE__ */ new Equal(),
  /* @__PURE__ */ new NotEqual(),
  /* @__PURE__ */ new GreaterThan(),
  /* @__PURE__ */ new LessThan(),
  /* @__PURE__ */ new In(),
  /* @__PURE__ */ new Like(),
]);

export const all_orders = /* @__PURE__ */ toList([
  /* @__PURE__ */ new Ascending(),
  /* @__PURE__ */ new Descending(),
]);

export const all_order_bys = [
  (var0, var1) => { return new OrderDomainBy(var0, var1); },
  (var0, var1) => { return new OrderMeasureBy(var0, var1); },
];

export const all_filter_conditions = [
  (var0, var1, var2) => { return new FilterDomain(var0, var1, var2); },
  (var0, var1, var2) => { return new FilterMeasure(var0, var1, var2); },
];

export const all_measure_elements = [
  (var0) => { return new MeasureOneToOne(var0); },
  (var0, var1) => { return new MeasureAgg(var0, var1); },
];

export const all_domain_elements = [
  (var0) => { return new DomainProperty(var0); },
  (var0, var1) => { return new DomainTimeline(var0, var1); },
];

export const all_projection_edits = [
  (var0) => { return new AddDomainElement(var0); },
  (var0, var1) => { return new AddMeasure(var0, var1); },
  (var0) => { return new AddFilter(var0); },
  (var0) => { return new AddOrderBy(var0); },
];

export const all_expressions = [
  (var0) => { return new Property(var0); },
  (var0) => { return new Literal(var0); },
  (var0, var1) => { return new Add(var0, var1); },
  (var0, var1) => { return new Subtract(var0, var1); },
  (var0, var1) => { return new Multiply(var0, var1); },
  (var0, var1) => { return new Divide(var0, var1); },
];

export const all_values = [
  (var0) => { return new IntValue(var0); },
  (var0) => { return new StringValue(var0); },
  (var0) => { return new FloatValue(var0); },
  (var0) => { return new BoolValue(var0); },
];
