"use client";

import useStateMachine, { t } from "@cassiozen/usestatemachine";
import { redirect, usePathname } from "next/navigation";
import { createContext, PropsWithChildren, useContext } from "react";

import { CEvents, CStates, events, states } from "@/lib/state-machine";
import { pathFromCState } from "@/utils";

// Define the shape of your context
interface ContextType {
  state: CStates;
  send: (event: CEvents) => void;
  onStateChange: (event: CEvents) => void;
  navigateToCState: (state: CStates) => void;
  navigateBack: () => void;
  nextEvents: CEvents[];
}

// Create the context initial value
const AppContext = createContext<ContextType | undefined>(undefined);

export const AppContextProvider = ({ children }: PropsWithChildren) => {
  const pathname = usePathname();
  const stateFromPath = CStates[pathname.split("/")[2] as CStates];
  const initial = stateFromPath || CStates.HOME;

  const [machine, send] = useStateMachine({
    schema: {
      context: t(),
      events,
    },
    context: {},
    initial,
    states,
  });

  const { value: currentState, nextEvents } = machine;

  const onStateChange = (event: CEvents) => {
    // @ts-expect-error TODO
    send(event);
    const nextRoute = states[currentState].on[event];
    if (nextRoute == null) {
      throw new Error(
        `State ${nextRoute} has no route mapping from event ${event}`,
      );
    }
    redirect(pathFromCState(nextRoute));
  };

  const navigateToCState = (state: CStates) => {
    const eventForNavigation = Object.entries(states[currentState].on).find(
      ([, toState]) => toState === state,
    );
    if (eventForNavigation == null) {
      throw new Error(
        `No valid transition from state ${currentState} to ${state}`,
      );
    }
    const [event, toState] = eventForNavigation;
    // @ts-expect-error TODO
    send(event);
    // always call redirect as client side routing URL may not match FSM internal state
    redirect(pathFromCState(toState));
  };

  const navigateBack = () => {
    onStateChange(CEvents.GO_BACK);
  };

  return (
    <AppContext.Provider
      value={{
        onStateChange,
        state: currentState,
        nextEvents,
        navigateToCState,
        navigateBack,
        // @ts-expect-error TODO
        send,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

// Create a custom hook to use the context
export const useAppContext = () => {
  const context = useContext(AppContext);
  if (context === undefined) {
    throw new Error("useAppContext must be used within a AppContext");
  }
  return context;
};
