/// <reference types="./blueprint.d.mts" />
import * as $json from "../../gleam_json/gleam/json.mjs";
import * as $dynamic from "../../gleam_stdlib/gleam/dynamic.mjs";
import * as $list from "../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import { None, Some } from "../../gleam_stdlib/gleam/option.mjs";
import * as $result from "../../gleam_stdlib/gleam/result.mjs";
import * as $string from "../../gleam_stdlib/gleam/string.mjs";
import { Ok, Error, toList, CustomType as $CustomType, isEqual } from "../gleam.mjs";
import * as $jsch from "../json/blueprint/schema.mjs";
import { Type } from "../json/blueprint/schema.mjs";
import { do_null as native_null } from "../json_blueprint_ffi.mjs";

export class Decoder extends $CustomType {
  constructor(dyn_decoder, schema, defs) {
    super();
    this.dyn_decoder = dyn_decoder;
    this.schema = schema;
    this.defs = defs;
  }
}

export class FieldDecoder extends $CustomType {
  constructor(dyn_decoder, field_schema, defs) {
    super();
    this.dyn_decoder = dyn_decoder;
    this.field_schema = field_schema;
    this.defs = defs;
  }
}

export function generate_json_schema(decoder) {
  let refs = (() => {
    let $ = decoder.defs;
    if ($.hasLength(0)) {
      return new None();
    } else {
      let xs = $;
      return new Some(xs);
    }
  })();
  return $jsch.to_json($jsch.new_schema(decoder.schema, refs));
}

export function self_decoder(lazy) {
  return new Decoder(
    (input) => { return lazy().dyn_decoder(input); },
    new $jsch.Ref("#"),
    toList([]),
  );
}

export function get_dynamic_decoder(decoder) {
  return decoder.dyn_decoder;
}

export function decode(decoder, json_string) {
  return $json.decode(json_string, decoder.dyn_decoder);
}

export function string() {
  return new Decoder(
    $dynamic.string,
    new Type(new $jsch.StringType()),
    toList([]),
  );
}

export function int() {
  return new Decoder(
    $dynamic.int,
    new Type(new $jsch.IntegerType()),
    toList([]),
  );
}

export function float() {
  return new Decoder(
    $dynamic.float,
    new Type(new $jsch.NumberType()),
    toList([]),
  );
}

export function bool() {
  return new Decoder(
    $dynamic.bool,
    new Type(new $jsch.BooleanType()),
    toList([]),
  );
}

export function list(decoder_type) {
  return new Decoder(
    $dynamic.list(decoder_type.dyn_decoder),
    new $jsch.Array(new Some(decoder_type.schema)),
    decoder_type.defs,
  );
}

export function reuse_decoder(decoder) {
  let def_name = "ref_" + $jsch.hash_schema_definition(decoder.schema);
  let ref_name = "#/$defs/" + def_name;
  let schema_with_rebased_self_refs = $jsch.map_ref(
    decoder.schema,
    (original_ref_name) => {
      if (original_ref_name === "#") {
        return ref_name;
      } else {
        let x = original_ref_name;
        return x;
      }
    },
  );
  return new Decoder(
    decoder.dyn_decoder,
    new $jsch.Ref(ref_name),
    (() => {
      let _pipe = decoder.defs;
      return $list.prepend(_pipe, [def_name, schema_with_rebased_self_refs]);
    })(),
  );
}

export function field(name, inner_type) {
  return new FieldDecoder(
    $dynamic.field(name, inner_type.dyn_decoder),
    [name, inner_type.schema],
    inner_type.defs,
  );
}

export function optional(decode) {
  return new Decoder(
    $dynamic.optional(decode.dyn_decoder),
    new $jsch.Nullable(decode.schema),
    decode.defs,
  );
}

export function optional_field(name, inner_type) {
  return new FieldDecoder(
    (value) => {
      let _pipe = $dynamic.optional_field(
        name,
        (dyn) => {
          let $ = isEqual(dyn, native_null());
          if (!$) {
            return $result.map(
              inner_type.dyn_decoder(dyn),
              (var0) => { return new Some(var0); },
            );
          } else {
            return new Ok(new None());
          }
        },
      )(value);
      return $result.map(_pipe, $option.flatten);
    },
    [name, new $jsch.Optional(inner_type.schema)],
    inner_type.defs,
  );
}

export function encode_optional_field(fields, name, value, encode_fn) {
  if (value instanceof Some) {
    let left = value[0];
    return $list.prepend(fields, [name, encode_fn(left)]);
  } else {
    return fields;
  }
}

export function union_type_encoder(of, encoder_fn) {
  let $ = encoder_fn(of);
  let field_name = $[0];
  let json_value = $[1];
  return $json.object(
    toList([["type", $json.string(field_name)], ["data", json_value]]),
  );
}

export function union_type_decoder(decoders) {
  let constructor = (type_str, data) => {
    let _pipe = decoders;
    let _pipe$1 = $list.find_map(
      _pipe,
      (dec) => {
        let $ = dec[0] === type_str;
        if ($) {
          return new Ok(dec[1].dyn_decoder(data));
        } else {
          return new Error(toList([]));
        }
      },
    );
    let _pipe$2 = $result.map_error(
      _pipe$1,
      (_) => {
        let valid_types = (() => {
          let _pipe$2 = decoders;
          let _pipe$3 = $list.map(_pipe$2, (dec) => { return dec[0]; });
          return $string.join(_pipe$3, ", ");
        })();
        return toList([
          new $dynamic.DecodeError(
            "valid constructor type, one of: " + valid_types,
            type_str,
            toList([]),
          ),
        ]);
      },
    );
    return $result.flatten(_pipe$2);
  };
  let enum_decoder = (data) => {
    let _pipe = $dynamic.decode2(
      constructor,
      $dynamic.field("type", $dynamic.string),
      $dynamic.field("data", $dynamic.dynamic),
    )(data);
    return $result.flatten(_pipe);
  };
  let schema = (() => {
    if (decoders.hasLength(0)) {
      return new $jsch.Object(toList([]), new Some(false), new None());
    } else if (decoders.hasLength(1)) {
      let name = decoders.head[0];
      let dec = decoders.head[1];
      return new $jsch.Object(
        toList([
          [
            "type",
            new $jsch.Enum(
              toList([$json.string(name)]),
              new Some(new $jsch.StringType()),
            ),
          ],
          ["data", dec.schema],
        ]),
        new Some(false),
        new Some(toList(["type", "data"])),
      );
    } else {
      let xs = decoders;
      let _pipe = $list.map(
        xs,
        (field_dec) => {
          let name = field_dec[0];
          let dec = field_dec[1];
          return new $jsch.Object(
            toList([
              [
                "type",
                new $jsch.Enum(
                  toList([$json.string(name)]),
                  new Some(new $jsch.StringType()),
                ),
              ],
              ["data", dec.schema],
            ]),
            new Some(false),
            new Some(toList(["type", "data"])),
          );
        },
      );
      return new $jsch.AnyOf(_pipe);
    }
  })();
  let defs = $list.flat_map(decoders, (dec) => { return dec[1].defs; });
  return new Decoder(enum_decoder, schema, defs);
}

export function enum_type_encoder(of, encoder_fn) {
  let field_name = encoder_fn(of);
  return $json.object(toList([["enum", $json.string(field_name)]]));
}

export function enum_type_decoder(decoders) {
  let constructor = (type_str) => {
    let _pipe = decoders;
    let _pipe$1 = $list.find_map(
      _pipe,
      (dec) => {
        let $ = dec[0] === type_str;
        if ($) {
          return new Ok(dec[1]);
        } else {
          return new Error(toList([]));
        }
      },
    );
    return $result.map_error(
      _pipe$1,
      (_) => {
        let valid_types = (() => {
          let _pipe$2 = decoders;
          let _pipe$3 = $list.map(_pipe$2, (dec) => { return dec[0]; });
          return $string.join(_pipe$3, ", ");
        })();
        return toList([
          new $dynamic.DecodeError(
            "valid constructor type, one of: " + valid_types,
            type_str,
            toList([]),
          ),
        ]);
      },
    );
  };
  let enum_decoder = (data) => {
    let _pipe = $dynamic.decode1(
      constructor,
      $dynamic.field("enum", $dynamic.string),
    )(data);
    return $result.flatten(_pipe);
  };
  return new Decoder(
    enum_decoder,
    (() => {
      let _pipe = $list.map(
        decoders,
        (field_dec) => { return $json.string(field_dec[0]); },
      );
      let _pipe$1 = ((enum_values) => {
        return toList([
          [
            "enum",
            new $jsch.Enum(enum_values, new Some(new $jsch.StringType())),
          ],
        ]);
      })(_pipe);
      return new $jsch.Object(
        _pipe$1,
        new Some(false),
        new Some(toList(["enum"])),
      );
    })(),
    toList([]),
  );
}

export function map(decoder, foo) {
  return new Decoder(
    (input) => { return $result.map(decoder.dyn_decoder(input), foo); },
    decoder.schema,
    decoder.defs,
  );
}

export function tuple2(decode1, decode2) {
  return new Decoder(
    $dynamic.tuple2(decode1.dyn_decoder, decode2.dyn_decoder),
    new $jsch.DetailedArray(
      new None(),
      new Some(toList([decode1.schema, decode2.schema])),
      new Some(2),
      new Some(2),
      new None(),
      new None(),
      new None(),
      new None(),
    ),
    $list.append(decode1.defs, decode2.defs),
  );
}

export function tuple3(decode1, decode2, decode3) {
  return new Decoder(
    $dynamic.tuple3(
      decode1.dyn_decoder,
      decode2.dyn_decoder,
      decode3.dyn_decoder,
    ),
    new $jsch.DetailedArray(
      new None(),
      new Some(toList([decode1.schema, decode2.schema, decode3.schema])),
      new Some(3),
      new Some(3),
      new None(),
      new None(),
      new None(),
      new None(),
    ),
    $list.concat(toList([decode1.defs, decode2.defs, decode3.defs])),
  );
}

export function tuple4(decode1, decode2, decode3, decode4) {
  return new Decoder(
    $dynamic.tuple4(
      decode1.dyn_decoder,
      decode2.dyn_decoder,
      decode3.dyn_decoder,
      decode4.dyn_decoder,
    ),
    new $jsch.DetailedArray(
      new None(),
      new Some(
        toList([decode1.schema, decode2.schema, decode3.schema, decode4.schema]),
      ),
      new Some(4),
      new Some(4),
      new None(),
      new None(),
      new None(),
      new None(),
    ),
    $list.concat(
      toList([decode1.defs, decode2.defs, decode3.defs, decode4.defs]),
    ),
  );
}

export function tuple5(decode1, decode2, decode3, decode4, decode5) {
  return new Decoder(
    $dynamic.tuple5(
      decode1.dyn_decoder,
      decode2.dyn_decoder,
      decode3.dyn_decoder,
      decode4.dyn_decoder,
      decode5.dyn_decoder,
    ),
    new $jsch.DetailedArray(
      new None(),
      new Some(
        toList([
          decode1.schema,
          decode2.schema,
          decode3.schema,
          decode4.schema,
          decode5.schema,
        ]),
      ),
      new Some(5),
      new Some(5),
      new None(),
      new None(),
      new None(),
      new None(),
    ),
    $list.concat(
      toList([
        decode1.defs,
        decode2.defs,
        decode3.defs,
        decode4.defs,
        decode5.defs,
      ]),
    ),
  );
}

export function tuple6(decode1, decode2, decode3, decode4, decode5, decode6) {
  return new Decoder(
    $dynamic.tuple6(
      decode1.dyn_decoder,
      decode2.dyn_decoder,
      decode3.dyn_decoder,
      decode4.dyn_decoder,
      decode5.dyn_decoder,
      decode6.dyn_decoder,
    ),
    new $jsch.DetailedArray(
      new None(),
      new Some(
        toList([
          decode1.schema,
          decode2.schema,
          decode3.schema,
          decode4.schema,
          decode5.schema,
          decode6.schema,
        ]),
      ),
      new Some(6),
      new Some(6),
      new None(),
      new None(),
      new None(),
      new None(),
    ),
    $list.concat(
      toList([
        decode1.defs,
        decode2.defs,
        decode3.defs,
        decode4.defs,
        decode5.defs,
        decode6.defs,
      ]),
    ),
  );
}

function create_object_schema(fields) {
  return new $jsch.Object(
    fields,
    new Some(false),
    new Some(
      $list.filter_map(
        fields,
        (field_dec) => {
          if (field_dec[1] instanceof $jsch.Optional) {
            return new Error(undefined);
          } else {
            let name = field_dec[0];
            return new Ok(name);
          }
        },
      ),
    ),
  );
}

export function decode0(constructor) {
  return new Decoder(
    (_) => { return new Ok(constructor); },
    new $jsch.Object(toList([]), new Some(false), new None()),
    toList([]),
  );
}

export function decode1(constructor, t1) {
  return new Decoder(
    $dynamic.decode1(constructor, t1.dyn_decoder),
    create_object_schema(toList([t1.field_schema])),
    t1.defs,
  );
}

export function decode2(constructor, t1, t2) {
  return new Decoder(
    $dynamic.decode2(constructor, t1.dyn_decoder, t2.dyn_decoder),
    create_object_schema(toList([t1.field_schema, t2.field_schema])),
    $list.concat(toList([t1.defs, t2.defs])),
  );
}

export function decode3(constructor, t1, t2, t3) {
  return new Decoder(
    $dynamic.decode3(
      constructor,
      t1.dyn_decoder,
      t2.dyn_decoder,
      t3.dyn_decoder,
    ),
    create_object_schema(
      toList([t1.field_schema, t2.field_schema, t3.field_schema]),
    ),
    $list.concat(toList([t1.defs, t2.defs, t3.defs])),
  );
}

export function decode4(constructor, t1, t2, t3, t4) {
  return new Decoder(
    $dynamic.decode4(
      constructor,
      t1.dyn_decoder,
      t2.dyn_decoder,
      t3.dyn_decoder,
      t4.dyn_decoder,
    ),
    create_object_schema(
      toList([
        t1.field_schema,
        t2.field_schema,
        t3.field_schema,
        t4.field_schema,
      ]),
    ),
    $list.concat(toList([t1.defs, t2.defs, t3.defs, t4.defs])),
  );
}

export function decode5(constructor, t1, t2, t3, t4, t5) {
  return new Decoder(
    $dynamic.decode5(
      constructor,
      t1.dyn_decoder,
      t2.dyn_decoder,
      t3.dyn_decoder,
      t4.dyn_decoder,
      t5.dyn_decoder,
    ),
    create_object_schema(
      toList([
        t1.field_schema,
        t2.field_schema,
        t3.field_schema,
        t4.field_schema,
        t5.field_schema,
      ]),
    ),
    $list.concat(toList([t1.defs, t2.defs, t3.defs, t4.defs, t5.defs])),
  );
}

export function decode6(constructor, t1, t2, t3, t4, t5, t6) {
  return new Decoder(
    $dynamic.decode6(
      constructor,
      t1.dyn_decoder,
      t2.dyn_decoder,
      t3.dyn_decoder,
      t4.dyn_decoder,
      t5.dyn_decoder,
      t6.dyn_decoder,
    ),
    create_object_schema(
      toList([
        t1.field_schema,
        t2.field_schema,
        t3.field_schema,
        t4.field_schema,
        t5.field_schema,
        t6.field_schema,
      ]),
    ),
    $list.concat(toList([t1.defs, t2.defs, t3.defs, t4.defs, t5.defs, t6.defs])),
  );
}

export function decode7(constructor, t1, t2, t3, t4, t5, t6, t7) {
  return new Decoder(
    $dynamic.decode7(
      constructor,
      t1.dyn_decoder,
      t2.dyn_decoder,
      t3.dyn_decoder,
      t4.dyn_decoder,
      t5.dyn_decoder,
      t6.dyn_decoder,
      t7.dyn_decoder,
    ),
    create_object_schema(
      toList([
        t1.field_schema,
        t2.field_schema,
        t3.field_schema,
        t4.field_schema,
        t5.field_schema,
        t6.field_schema,
        t7.field_schema,
      ]),
    ),
    $list.concat(
      toList([t1.defs, t2.defs, t3.defs, t4.defs, t5.defs, t6.defs, t7.defs]),
    ),
  );
}

export function decode8(constructor, t1, t2, t3, t4, t5, t6, t7, t8) {
  return new Decoder(
    $dynamic.decode8(
      constructor,
      t1.dyn_decoder,
      t2.dyn_decoder,
      t3.dyn_decoder,
      t4.dyn_decoder,
      t5.dyn_decoder,
      t6.dyn_decoder,
      t7.dyn_decoder,
      t8.dyn_decoder,
    ),
    create_object_schema(
      toList([
        t1.field_schema,
        t2.field_schema,
        t3.field_schema,
        t4.field_schema,
        t5.field_schema,
        t6.field_schema,
        t7.field_schema,
        t8.field_schema,
      ]),
    ),
    $list.concat(
      toList([
        t1.defs,
        t2.defs,
        t3.defs,
        t4.defs,
        t5.defs,
        t6.defs,
        t7.defs,
        t8.defs,
      ]),
    ),
  );
}

export function decode9(constructor, t1, t2, t3, t4, t5, t6, t7, t8, t9) {
  return new Decoder(
    $dynamic.decode9(
      constructor,
      t1.dyn_decoder,
      t2.dyn_decoder,
      t3.dyn_decoder,
      t4.dyn_decoder,
      t5.dyn_decoder,
      t6.dyn_decoder,
      t7.dyn_decoder,
      t8.dyn_decoder,
      t9.dyn_decoder,
    ),
    create_object_schema(
      toList([
        t1.field_schema,
        t2.field_schema,
        t3.field_schema,
        t4.field_schema,
        t5.field_schema,
        t6.field_schema,
        t7.field_schema,
        t8.field_schema,
        t9.field_schema,
      ]),
    ),
    $list.concat(
      toList([
        t1.defs,
        t2.defs,
        t3.defs,
        t4.defs,
        t5.defs,
        t6.defs,
        t7.defs,
        t8.defs,
        t9.defs,
      ]),
    ),
  );
}

export function encode_tuple2(tuple, encode1, encode2) {
  let t1 = tuple[0];
  let t2 = tuple[1];
  return $json.preprocessed_array(toList([encode1(t1), encode2(t2)]));
}

export function encode_tuple3(tuple, encode1, encode2, encode3) {
  let t1 = tuple[0];
  let t2 = tuple[1];
  let t3 = tuple[2];
  return $json.preprocessed_array(
    toList([encode1(t1), encode2(t2), encode3(t3)]),
  );
}

export function encode_tuple4(tuple, encode1, encode2, encode3, encode4) {
  let t1 = tuple[0];
  let t2 = tuple[1];
  let t3 = tuple[2];
  let t4 = tuple[3];
  return $json.preprocessed_array(
    toList([encode1(t1), encode2(t2), encode3(t3), encode4(t4)]),
  );
}

export function encode_tuple5(
  tuple,
  encode1,
  encode2,
  encode3,
  encode4,
  encode5
) {
  let t1 = tuple[0];
  let t2 = tuple[1];
  let t3 = tuple[2];
  let t4 = tuple[3];
  let t5 = tuple[4];
  return $json.preprocessed_array(
    toList([encode1(t1), encode2(t2), encode3(t3), encode4(t4), encode5(t5)]),
  );
}

export function encode_tuple6(
  tuple,
  encode1,
  encode2,
  encode3,
  encode4,
  encode5,
  encode6
) {
  let t1 = tuple[0];
  let t2 = tuple[1];
  let t3 = tuple[2];
  let t4 = tuple[3];
  let t5 = tuple[4];
  let t6 = tuple[5];
  return $json.preprocessed_array(
    toList([
      encode1(t1),
      encode2(t2),
      encode3(t3),
      encode4(t4),
      encode5(t5),
      encode6(t6),
    ]),
  );
}

export function encode_tuple7(
  tuple,
  encode1,
  encode2,
  encode3,
  encode4,
  encode5,
  encode6,
  encode7
) {
  let t1 = tuple[0];
  let t2 = tuple[1];
  let t3 = tuple[2];
  let t4 = tuple[3];
  let t5 = tuple[4];
  let t6 = tuple[5];
  let t7 = tuple[6];
  return $json.preprocessed_array(
    toList([
      encode1(t1),
      encode2(t2),
      encode3(t3),
      encode4(t4),
      encode5(t5),
      encode6(t6),
      encode7(t7),
    ]),
  );
}

export function encode_tuple8(
  tuple,
  encode1,
  encode2,
  encode3,
  encode4,
  encode5,
  encode6,
  encode7,
  encode8
) {
  let t1 = tuple[0];
  let t2 = tuple[1];
  let t3 = tuple[2];
  let t4 = tuple[3];
  let t5 = tuple[4];
  let t6 = tuple[5];
  let t7 = tuple[6];
  let t8 = tuple[7];
  return $json.preprocessed_array(
    toList([
      encode1(t1),
      encode2(t2),
      encode3(t3),
      encode4(t4),
      encode5(t5),
      encode6(t6),
      encode7(t7),
      encode8(t8),
    ]),
  );
}

export function encode_tuple9(
  tuple,
  encode1,
  encode2,
  encode3,
  encode4,
  encode5,
  encode6,
  encode7,
  encode8,
  encode9
) {
  let t1 = tuple[0];
  let t2 = tuple[1];
  let t3 = tuple[2];
  let t4 = tuple[3];
  let t5 = tuple[4];
  let t6 = tuple[5];
  let t7 = tuple[6];
  let t8 = tuple[7];
  let t9 = tuple[8];
  return $json.preprocessed_array(
    toList([
      encode1(t1),
      encode2(t2),
      encode3(t3),
      encode4(t4),
      encode5(t5),
      encode6(t6),
      encode7(t7),
      encode8(t8),
      encode9(t9),
    ]),
  );
}
