/// <reference types="./buzz_model.d.mts" />
import * as $json from "../../gleam_json/gleam/json.mjs";
import * as $dict from "../../gleam_stdlib/gleam/dict.mjs";
import * as $list from "../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import * as $pair from "../../gleam_stdlib/gleam/pair.mjs";
import * as $result from "../../gleam_stdlib/gleam/result.mjs";
import * as $set from "../../gleam_stdlib/gleam/set.mjs";
import * as $string from "../../gleam_stdlib/gleam/string.mjs";
import * as $blueprint from "../../json_blueprint/json/blueprint.mjs";
import { Ok, Error, toList, CustomType as $CustomType, isEqual } from "../gleam.mjs";

export class Integer extends $CustomType {}

export class Float extends $CustomType {}

export class String extends $CustomType {}

export class Boolean extends $CustomType {}

export class Timestamp extends $CustomType {}

export class Property extends $CustomType {
  constructor(name, property_type, nullable, table_name, column_name, primary_key, unique, description) {
    super();
    this.name = name;
    this.property_type = property_type;
    this.nullable = nullable;
    this.table_name = table_name;
    this.column_name = column_name;
    this.primary_key = primary_key;
    this.unique = unique;
    this.description = description;
  }
}

export class PropertyReference extends $CustomType {
  constructor(entity, property) {
    super();
    this.entity = entity;
    this.property = property;
  }
}

export class OneToOne extends $CustomType {}

export class OneToMany extends $CustomType {}

export class ManyToOne extends $CustomType {}

export class ManyToMany extends $CustomType {}

export class Relationship extends $CustomType {
  constructor(name, from, to, relation_type, description) {
    super();
    this.name = name;
    this.from = from;
    this.to = to;
    this.relation_type = relation_type;
    this.description = description;
  }
}

export class Entity extends $CustomType {
  constructor(name, properties, description) {
    super();
    this.name = name;
    this.properties = properties;
    this.description = description;
  }
}

export class BuzzModel extends $CustomType {
  constructor(entities, relationships, description) {
    super();
    this.entities = entities;
    this.relationships = relationships;
    this.description = description;
  }
}

export class EntityAlreadyExists extends $CustomType {
  constructor(error) {
    super();
    this.error = error;
  }
}

function dfs(
  entity_lookup,
  current,
  target,
  visited_relations,
  visited_entities,
  path
) {
  let $ = isEqual(current.entity, target.entity);
  if ($) {
    return toList([path]);
  } else {
    let relations_to_current_entity = (() => {
      let _pipe = $dict.get(entity_lookup, current.entity);
      return $result.map(
        _pipe,
        (s) => {
          let relation_to_non_visited_entities = $set.filter(
            s,
            (r) => {
              let have_been_here = $set.contains(
                visited_entities,
                r.from.entity,
              ) || $set.contains(visited_entities, r.to.entity);
              return !have_been_here;
            },
          );
          return $set.difference(
            relation_to_non_visited_entities,
            visited_relations,
          );
        },
      );
    })();
    if (!relations_to_current_entity.isOk()) {
      return toList([]);
    } else {
      let available_next_rel = relations_to_current_entity[0];
      let $1 = $set.is_empty(available_next_rel);
      if ($1) {
        return toList([]);
      } else {
        return $set.fold(
          available_next_rel,
          toList([]),
          (acc, r) => {
            let new_visited_relations = $set.insert(visited_relations, r);
            let new_visited_entities = $set.insert(
              visited_entities,
              current.entity,
            );
            let new_path = (() => {
              let _pipe = path;
              return $list.append(_pipe, toList([r]));
            })();
            let _pipe = (() => {
              let $2 = isEqual(r.from.entity, current.entity);
              if ($2) {
                return dfs(
                  entity_lookup,
                  r.to,
                  target,
                  new_visited_relations,
                  new_visited_entities,
                  new_path,
                );
              } else {
                return dfs(
                  entity_lookup,
                  r.from,
                  target,
                  new_visited_relations,
                  new_visited_entities,
                  new_path,
                );
              }
            })();
            return $list.append(_pipe, acc);
          },
        );
      }
    }
  }
}

export function find_all_connected_paths(from, to, buzz) {
  let entity_lookup = (() => {
    let _pipe = buzz.relationships;
    let _pipe$1 = $list.flat_map(
      _pipe,
      (r) => { return toList([[r.from.entity, r], [r.to.entity, r]]); },
    );
    return $list.fold(
      _pipe$1,
      $dict.new$(),
      (acc, kv) => {
        return $dict.upsert(
          acc,
          $pair.first(kv),
          (x) => {
            if (x instanceof $option.None) {
              let _pipe$2 = $set.new$();
              return $set.insert(_pipe$2, $pair.second(kv));
            } else {
              let v = x[0];
              return $set.insert(v, $pair.second(kv));
            }
          },
        );
      },
    );
  })();
  return dfs(entity_lookup, from, to, $set.new$(), $set.new$(), toList([]));
}

export function find_property_reference_by_name(
  buzz,
  entity_name,
  property_name
) {
  return $result.try$(
    (() => {
      let _pipe = buzz.entities;
      let _pipe$1 = $list.find(_pipe, (e) => { return e.name === entity_name; });
      return $result.map_error(
        _pipe$1,
        (_) => { return ("Entity '" + entity_name) + "'does not exist"; },
      );
    })(),
    (entity) => {
      return $result.try$(
        (() => {
          let _pipe = entity.properties;
          let _pipe$1 = $list.find(
            _pipe,
            (p) => { return p.name === property_name; },
          );
          return $result.map_error(
            _pipe$1,
            (_) => { return ("Property '" + property_name) + "'does not exist"; },
          );
        })(),
        (property) => { return new Ok(new PropertyReference(entity, property)); },
      );
    },
  );
}

export function validate_entity_name(name) {
  let $ = $string.is_empty(name);
  if ($) {
    return new Error("Entity name cannot be empty");
  } else {
    return new Ok(undefined);
  }
}

export function validate_property_name(name) {
  let $ = $string.is_empty(name);
  if ($) {
    return new Error("Property name cannot be empty");
  } else {
    return new Ok(undefined);
  }
}

export function validate_entity(entity) {
  let $ = validate_entity_name(entity.name);
  if (!$.isOk()) {
    let msg = $[0];
    return new Error(msg);
  } else {
    let $1 = $list.is_empty(entity.properties);
    if ($1) {
      return new Error("Entity must have at least one property");
    } else {
      return new Ok(entity);
    }
  }
}

export function validate_relationship(relationship, buzz) {
  return $result.try$(
    $result.map_error(
      $list.find(
        buzz.entities,
        (t) => { return isEqual(t, relationship.from.entity); },
      ),
      (_) => { return "From entity does not exist"; },
    ),
    (from_entity) => {
      return $result.try$(
        $result.map_error(
          $list.find(
            from_entity.properties,
            (t) => { return isEqual(t, relationship.from.property); },
          ),
          (_) => { return "From Property does not exist"; },
        ),
        (_) => {
          return $result.try$(
            $result.map_error(
              $list.find(
                buzz.entities,
                (t) => { return isEqual(t, relationship.to.entity); },
              ),
              (_) => { return "To entity does not exist"; },
            ),
            (to_entity) => {
              return $result.try$(
                $result.map_error(
                  $list.find(
                    to_entity.properties,
                    (t) => { return isEqual(t, relationship.to.property); },
                  ),
                  (_) => { return "To property does not exist"; },
                ),
                (_) => { return new Ok(relationship); },
              );
            },
          );
        },
      );
    },
  );
}

export function property_type_to_string(prop_type) {
  if (prop_type instanceof Integer) {
    return "integer";
  } else if (prop_type instanceof Float) {
    return "float";
  } else if (prop_type instanceof String) {
    return "string";
  } else if (prop_type instanceof Boolean) {
    return "boolean";
  } else {
    return "timestamp";
  }
}

export function property_type_decoder() {
  return $blueprint.enum_type_decoder(
    toList([
      ["integer", new Integer()],
      ["float", new Float()],
      ["string", new String()],
      ["boolean", new Boolean()],
      ["timestamp", new Timestamp()],
    ]),
  );
}

export function property_type_to_json(prop_type) {
  return $blueprint.enum_type_encoder(prop_type, property_type_to_string);
}

export function property_decoder() {
  return $blueprint.union_type_decoder(
    toList([
      [
        "property",
        $blueprint.decode8(
          (var0, var1, var2, var3, var4, var5, var6, var7) => {
            return new Property(var0, var1, var2, var3, var4, var5, var6, var7);
          },
          $blueprint.field("name", $blueprint.string()),
          $blueprint.field("property_type", property_type_decoder()),
          $blueprint.field("nullable", $blueprint.bool()),
          $blueprint.field("table_name", $blueprint.string()),
          $blueprint.field("column_name", $blueprint.string()),
          $blueprint.field("primary_key", $blueprint.bool()),
          $blueprint.field("unique", $blueprint.bool()),
          $blueprint.field("description", $blueprint.string()),
        ),
      ],
    ]),
  );
}

export function property_to_json(prop) {
  return $blueprint.union_type_encoder(
    prop,
    (prop_case) => {
      {
        let prop$1 = prop_case;
        return [
          "property",
          $json.object(
            toList([
              ["name", $json.string(prop$1.name)],
              ["property_type", property_type_to_json(prop$1.property_type)],
              ["nullable", $json.bool(prop$1.nullable)],
              ["table_name", $json.string(prop$1.table_name)],
              ["column_name", $json.string(prop$1.column_name)],
              ["primary_key", $json.bool(prop$1.primary_key)],
              ["unique", $json.bool(prop$1.unique)],
              ["description", $json.string(prop$1.description)],
            ]),
          ),
        ];
      }
    },
  );
}

export function entity_decoder() {
  return $blueprint.union_type_decoder(
    toList([
      [
        "entity",
        $blueprint.decode3(
          (var0, var1, var2) => { return new Entity(var0, var1, var2); },
          $blueprint.field("name", $blueprint.string()),
          $blueprint.field("properties", $blueprint.list(property_decoder())),
          $blueprint.field("description", $blueprint.string()),
        ),
      ],
    ]),
  );
}

export function entity_to_json(entity) {
  return $blueprint.union_type_encoder(
    entity,
    (entity_case) => {
      {
        let name = entity_case.name;
        let properties = entity_case.properties;
        let description = entity_case.description;
        return [
          "entity",
          $json.object(
            toList([
              ["name", $json.string(name)],
              ["properties", $json.array(properties, property_to_json)],
              ["description", $json.string(description)],
            ]),
          ),
        ];
      }
    },
  );
}

export function property_reference_decoder() {
  return $blueprint.union_type_decoder(
    toList([
      [
        "property_reference",
        $blueprint.decode2(
          (var0, var1) => { return new PropertyReference(var0, var1); },
          $blueprint.field("entity", entity_decoder()),
          $blueprint.field("property", property_decoder()),
        ),
      ],
    ]),
  );
}

export function property_reference_to_json(ref) {
  return $blueprint.union_type_encoder(
    ref,
    (ref_case) => {
      {
        let entity = ref_case.entity;
        let property = ref_case.property;
        return [
          "property_reference",
          $json.object(
            toList([
              ["entity", entity_to_json(entity)],
              ["property", property_to_json(property)],
            ]),
          ),
        ];
      }
    },
  );
}

export function relation_type_decoder() {
  return $blueprint.union_type_decoder(
    toList([
      ["one_to_one", $blueprint.decode0(new OneToOne())],
      ["one_to_many", $blueprint.decode0(new OneToMany())],
      ["many_to_one", $blueprint.decode0(new ManyToOne())],
      ["many_to_many", $blueprint.decode0(new ManyToMany())],
    ]),
  );
}

export function relation_type_to_json(rel_type) {
  return $blueprint.union_type_encoder(
    rel_type,
    (type_case) => {
      if (type_case instanceof OneToOne) {
        return ["one_to_one", $json.object(toList([]))];
      } else if (type_case instanceof OneToMany) {
        return ["one_to_many", $json.object(toList([]))];
      } else if (type_case instanceof ManyToOne) {
        return ["many_to_one", $json.object(toList([]))];
      } else {
        return ["many_to_many", $json.object(toList([]))];
      }
    },
  );
}

export function relationship_decoder() {
  return $blueprint.union_type_decoder(
    toList([
      [
        "relationship",
        $blueprint.decode5(
          (var0, var1, var2, var3, var4) => {
            return new Relationship(var0, var1, var2, var3, var4);
          },
          $blueprint.field("name", $blueprint.string()),
          $blueprint.field("from", property_reference_decoder()),
          $blueprint.field("to", property_reference_decoder()),
          $blueprint.field("relation_type", relation_type_decoder()),
          $blueprint.field("description", $blueprint.string()),
        ),
      ],
    ]),
  );
}

export function relationship_to_json(rel) {
  return $blueprint.union_type_encoder(
    rel,
    (rel_case) => {
      {
        let name = rel_case.name;
        let from = rel_case.from;
        let to = rel_case.to;
        let relation_type = rel_case.relation_type;
        let description = rel_case.description;
        return [
          "relationship",
          $json.object(
            toList([
              ["name", $json.string(name)],
              ["from", property_reference_to_json(from)],
              ["to", property_reference_to_json(to)],
              ["relation_type", relation_type_to_json(relation_type)],
              ["description", $json.string(description)],
            ]),
          ),
        ];
      }
    },
  );
}

export function buzz_model_decoder() {
  return $blueprint.union_type_decoder(
    toList([
      [
        "buzz_model",
        $blueprint.decode3(
          (var0, var1, var2) => { return new BuzzModel(var0, var1, var2); },
          $blueprint.field("entities", $blueprint.list(entity_decoder())),
          $blueprint.field(
            "relationships",
            $blueprint.list(relationship_decoder()),
          ),
          $blueprint.field("description", $blueprint.string()),
        ),
      ],
    ]),
  );
}

export function buzz_model_from_json(json_string) {
  return $blueprint.decode(buzz_model_decoder(), json_string);
}

export function buzz_model_to_json(model) {
  return $blueprint.union_type_encoder(
    model,
    (model_case) => {
      {
        let entities = model_case.entities;
        let relationships = model_case.relationships;
        let description = model_case.description;
        return [
          "buzz_model",
          $json.object(
            toList([
              ["entities", $json.array(entities, entity_to_json)],
              [
                "relationships",
                $json.array(relationships, relationship_to_json),
              ],
              ["description", $json.string(description)],
            ]),
          ),
        ];
      }
    },
  );
}

export function buzz_model_to_json_string(model) {
  let _pipe = buzz_model_to_json(model);
  return $json.to_string(_pipe);
}

export function buzz_model_error_decoder() {
  return $blueprint.union_type_decoder(
    toList([
      [
        "entity_already_exists",
        $blueprint.decode1(
          (var0) => { return new EntityAlreadyExists(var0); },
          $blueprint.field("error", buzz_model_error_decoder()),
        ),
      ],
    ]),
  );
}

export function buzz_model_error_to_json(error) {
  return $blueprint.union_type_encoder(
    error,
    (error_case) => {
      {
        let err = error_case.error;
        return [
          "entity_already_exists",
          $json.object(toList([["error", buzz_model_error_to_json(err)]])),
        ];
      }
    },
  );
}
